/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation, Link as RouterLink} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Link from '@mui/material/Link';
// import makeStyles from '@mui/styles/makeStyles';
// // import withStyles from '@mui/styles/withStyles';
// import fmsfav from 'app/assets/fmsfav.png';
import fmsfav from 'app/assets/fmsfav.svg';
import logo from 'app/assets/logo.svg';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import BrightnessAutoOutlinedIcon from '@mui/icons-material/BrightnessAutoOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CampaignIcon from '@mui/icons-material/Campaign';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import InsightsIcon from '@mui/icons-material/Insights';
import CloseIcon from '@mui/icons-material/Close';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import GA from 'app/utils/GoogleAnalytics4';
// import logo from '../logo.svg';

// const useStyles = makeStyles((theme) => ({
//   '@global': {
//     ul: {
//       margin: 0,
//       padding: 0,
//       listStyle: 'none',
//     },
//   },
//   appBar: {
//     // borderBottom: `1px solid ${theme.palette.divider}`,
//     backgroundColor: '#fafafa',
//   },
//   guest-toolbar: {
//     flexWrap: 'wrap',
//     height: '58px',
//     minHeight: '58px',
//   },
//   logo: {
//     maxWidth: 200,
//     paddingTop: '5px',
//   },
//   guest-toolbarTitle: {
//     flexGrow: 1,
//   },
//   link: {
//     margin: theme.spacing(1.1, 1.1),
//     cursor: 'pointer',
//   },
//   whitelink: {
//     margin: theme.spacing(1, 1.5),
//     cursor: 'pointer',
//     color: '#ffffff',
//   },
//   cardGuestNavbar: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'baseline',
//     marginBottom: theme.spacing(2),
//   },
// }));

// const StyledMenu = withStyles({
//   paper: {
//     border: '1px solid #ffffff',
//     marginTop: '5px',
//     marginLeft: '16px',
//     width: '200px',
//     zIndex: 1000,
//   },
// })((props) => (
//   <Menu
//     elevation={0}
//     // getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'right',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right',
//     }}
//     {...props}
//   />
// ));

// const MegaMenu = withStyles({
//   paper: {
//     border: '1px solid #d3d4d5',
//     marginTop: '20px',
//     // marginLeft: '16px',
//     left: 0,
//     right: 0,
//     top: '-1px',
//     width: '100%',
//     zIndex: 1000,
//   },
// })((props) => (
//   <Menu
//     elevation={0}
//     // getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'center',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'center',
//     }}
//     {...props}
//   />
// ));

export const GuestNavbar = function () {
  const navigate = useNavigate();
  const location = useLocation();
  // const classes = useStyles();

  const [isBelowFold, setIsBelowFold] = useState(true);
  // const [isOnHomePage, setIsOnHomePage] = useState(false); // New state for route-based visibility

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsBelowFold(true);
    }
  });

  useEffect(() => {
    // Ensure window is defined (i.e., running in the browser)
    if (typeof window !== 'undefined' && location.pathname === '/') {
      const checkScroll = () => {
        // console.log('LOCATION PATH', location.pathname);
        if (window.pageYOffset > window.innerHeight * 0.58) {
          setIsBelowFold(true);
        } else {
          setIsBelowFold(false);
        }
      };

      window.addEventListener('scroll', checkScroll);

      // Check initial position
      if (location.pathname !== '/') {
        setIsBelowFold(true);
      } else {
        checkScroll();
      }

      return () => {
        window.removeEventListener('scroll', checkScroll);
      };
    }
  });

  // useEffect(() => {
  //   const checkScroll = () => {
  //     if (typeof window !== 'undefined') {
  //       setIsBelowFold(window.pageYOffset > window.innerHeight * 0.58);
  //     }
  //   };

  //   if (isOnHomePage) { // Only set up the listener if on the homepage
  //     window.addEventListener('scroll', checkScroll);
  //     checkScroll(); // Initial check
  //   }

  //   return () => window.removeEventListener('scroll', checkScroll);
  // }, [isOnHomePage]);

  // // Effect for handling route changes
  // useEffect(() => {
  //   setIsOnHomePage(location.pathname === '/');
  // }, [location.pathname]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [productMenu, setProductMenu] = useState(null);
  const [solutionsMenu, setSolutionsMenu] = useState(null);
  const [activeMenu, setActiveMenu] = useState(undefined);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };
  if (location.pathname === '/register' || location.pathname === '/free-trial') {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="static" elevation={0} style={{backgroundColor: '#fafafa', height: '56px'}} className="guest-appBar">
          <Toolbar className="guest-toolbar">
            <div
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.scrollTo(0, 0), navigate('/');
                }
              }}
              style={{cursor: 'pointer'}}>
              <img
                // src="https://storage.googleapis.com/find-me-sales-bucket/assets/logo.svg"
                src={logo}
                width="200px"
                height="auto"
                alt="logo"
                loading="lazy"
                className="logo"
              />
            </div>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="static" elevation={0} className="guest-appBar" style={{backgroundColor: '#fafafa', height: '56px'}}>
          <Toolbar className="guest-toolbar">
            <div
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.scrollTo(0, 0), navigate('/');
                }
              }}
              style={{cursor: 'pointer'}}>
              <img
                src="https://storage.googleapis.com/find-me-sales-bucket/assets/logo.svg"
                // src={logo}
                loading="lazy"
                width="200px"
                height="auto"
                alt="logo"
                className="logo"
              />
            </div>
            <Typography
              variant="body"
              color="inherit"
              href="/"
              noWrap
              className="guest-toolbarTitle"
            />
            <Hidden mdDown>
              {/* <nav> */}
              {/* {isBelowFold && ( */}
              <React.Fragment>
              <Link
                  component={RouterLink}
                  to="/leadsearch"
                  variant="button"
                  color="textPrimary"
                  onMouseEnter={(event) => setActiveMenu(undefined)}
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    fontSize: '1rem',
                    textDecoration: 'none',
                  }}
                  cursor="pointer"
                  onClick={() => {
                    navigate('/leadsearch');
                  }}
                  className="bluelink">
                  Leadsearch
                </Link>
                <div>
                  <Link
                    component={RouterLink}
                    to="/product"
                    variant="button"
                    color="textPrimary"
                    style={{
                      textTransform: 'capitalize',
                      fontWeight: '600',
                      fontSize: '1rem',
                      textDecoration: 'none',
                    }}
                    onMouseEnter={(event) => setActiveMenu('Product')}
                    onClick={() => {
                      setActiveMenu(undefined);
                      navigate('product');
                    }}
                    className="bluelink">
                    Product
                  </Link>
                  {activeMenu === 'Product' ? (
                    <Grid
                      onMouseLeave={(event) => setActiveMenu(undefined)}
                      container
                      style={{
                        position: 'fixed',
                        top: '58px',
                        padding: '48px 24px 48px 48px',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        width: '100%',
                        height: '50%',
                        minHeight: '400px',
                        boxShadow:
                          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                      }}>
                      <Grid
                        xs={3}
                        item
                        style={{
                          // padding: '0px 16px',
                          borderRightStyle: 'solid',
                          borderRightWidth: '1px',
                          borderRightColor: 'rgb(235, 235, 235)',
                        }}>
                        <Grid container style={{marginLeft: '-34px'}}>
                          <Grid xs={2} lg={2} />
                          <Grid item xs={10} lg={10}>
                            <Typography
                              variant="h5"
                              align="center"
                              color="primary"
                              fontWeight="fontWeightBold"
                              paragraph
                              style={{
                                textAlign: 'left',
                                // paddingBottom: '12px',
                                fontWeight: '600',
                                // paddingLeft: '16px',
                              }}>
                              Overview
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{marginLeft: '-34px', cursor: 'pointer'}}
                          onClick={() => {
                            setActiveMenu(undefined);
                            navigate('product');
                          }}>
                          <Grid container xs={2} justifyContent="right">
                            {/* <p>hey</p> */}
                            <img
                              src={fmsfav}
                              alt="logo"
                              style={{
                                maxHeight: '20px',
                                marginTop: '6px',
                                right: 0,
                                marginRight: '16px',
                              }}
                            />
                          </Grid>
                          <Grid container xs={10}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                // color="primary"
                                color="#000"
                                fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '6px',
                                  fontWeight: '600',
                                }}>
                                Product Overview
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                color="#000"
                                // fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  // paddingBottom: '12px',
                                  margin: '8px 0px',
                                }}>
                                Powerful yet simple to use features to increase sales.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{marginLeft: '-34px', cursor: 'pointer'}}
                          onClick={() => {
                            setActiveMenu(undefined);
                            navigate('/our-data');
                          }}>
                          <Grid container xs={2} justifyContent="right">
                            {/* <p>hey</p> */}
                            <AssuredWorkloadIcon
                              color="primary"
                              style={{
                                maxHeight: '20px',
                                marginTop: '6px',
                                right: 0,
                                marginRight: '14px',
                                color: '#003671',
                              }}
                            />
                          </Grid>
                          <Grid container xs={10}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                // color="primary"
                                color="#000"
                                fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '6px',
                                  fontWeight: '600',
                                }}>
                                Our Data
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                // color="primary"
                                color="#000"
                                // fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  // paddingBottom: '12px',
                                  marginBottom: '0px',
                                  margin: '8px 0px',
                                }}>
                                Find out how to use fully compliant data to boost sales.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={9} item>
                        <Grid container style={{padding: '0px 0px 0px 48px'}}>
                          <Grid
                            xs={12}
                            style={{
                              padding: '0px 0px 0px 18px',
                            }}>
                            <Typography
                              variant="h5"
                              align="center"
                              color="primary"
                              fontWeight="fontWeightBold"
                              paragraph
                              style={{
                                textAlign: 'left',
                                // paddingBottom: '12px',
                                paddingLeft: '12px',
                                fontWeight: '600',
                              }}>
                              Features
                            </Typography>
                          </Grid>
                          <Grid
                            xs={6}
                            item
                            style={{
                              padding: '0px 0px 0px 16px',
                            }}>
                            <Grid
                              container
                              xs={12}
                              style={{marginLeft: '-34px', cursor: 'pointer'}}
                              onClick={() => {
                                setActiveMenu(undefined);
                                navigate('product/leadsearch-sales-intelligence');
                              }}>
                              <Grid container xs={1}>
                                {/* <p>hey</p> */}
                                <ScreenSearchDesktopOutlinedIcon
                                  color="primary"
                                  style={{
                                    marginTop: '6px',
                                    color: '#003671',
                                  }}
                                />
                              </Grid>
                              <Grid container xs={11}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    align="left"
                                    // color="primary"
                                    color="#000"
                                    fontWeight="fontWeightBold"
                                    paragraph
                                    style={{
                                      textAlign: 'left',
                                      marginBottom: '0px',
                                      marginTop: '6px',
                                      fontWeight: '600',
                                    }}>
                                    Leadsearch Sales Intelligence
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    align="left"
                                    // color="primary"
                                    color="#000"
                                    // fontWeight="fontWeightBold"
                                    paragraph
                                    style={{
                                      textAlign: 'left',
                                      // paddingBottom: '12px',
                                      marginBottom: '8px',
                                      marginTop: '8px',
                                      width: '100%',
                                    }}>
                                    Use our real-time, filterable company database to find perfect
                                    business leads.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              xs={12}
                              style={{marginLeft: '-34px', cursor: 'pointer'}}
                              onClick={() => {
                                setActiveMenu(undefined);
                                navigate('/product/sales-automation');
                              }}>
                              <Grid container xs={1}>
                                {/* <p>hey</p> */}
                                <BrightnessAutoOutlinedIcon
                                  color="primary"
                                  style={{
                                    marginTop: '6px',
                                    color: '#003671',
                                  }}
                                />
                              </Grid>
                              <Grid container xs={11}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    align="left"
                                    color="#000"
                                    // color="primary"
                                    fontWeight="fontWeightBold"
                                    paragraph
                                    style={{
                                      textAlign: 'left',
                                      marginBottom: '0px',
                                      marginTop: '6px',
                                      fontWeight: '600',
                                    }}>
                                    Sales Automation
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    align="left"
                                    color="#000"
                                    // color="primary"
                                    // fontWeight="fontWeightBold"
                                    paragraph
                                    style={{
                                      textAlign: 'left',
                                      // paddingBottom: '12px',
                                      marginBottom: '8px',
                                      marginTop: '8px',
                                      width: '100%',
                                    }}>
                                    Create Automation Flows and Campaigns to automate your sales
                                    outreach.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            xs={6}
                            item
                            style={{
                              padding: '0px 16px',
                            }}>
                            <Grid
                              container
                              xs={12}
                              style={{marginLeft: '-34px', cursor: 'pointer'}}
                              onClick={() => {
                                setActiveMenu(undefined);
                                navigate('product/ai-analysis-and-crm');
                              }}>
                              <Grid container xs={1}>
                                {/* <p>hey</p> */}
                                <SelectAllIcon
                                  color="primary"
                                  style={{
                                    marginTop: '6px',
                                    color: '#003671',
                                  }}
                                />
                              </Grid>
                              <Grid container xs={11}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    align="left"
                                    // color="primary"
                                    color="#000"
                                    fontWeight="fontWeightBold"
                                    paragraph
                                    style={{
                                      textAlign: 'left',
                                      marginBottom: '0px',
                                      marginTop: '6px',
                                      fontWeight: '600',
                                    }}>
                                    AI Analysis + CRM
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    align="left"
                                    // color="primary"
                                    color="#000"
                                    // fontWeight="fontWeightBold"
                                    paragraph
                                    style={{
                                      textAlign: 'left',
                                      // paddingBottom: '12px',
                                      marginBottom: '8px',
                                      marginTop: '8px',
                                      width: '100%',
                                    }}>
                                    AI researches leads for you, finding contact info and context
                                    for your CRM.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              xs={12}
                              style={{marginLeft: '-34px', cursor: 'pointer'}}
                              onClick={() => {
                                setActiveMenu(undefined);
                                navigate('product/connect-email');
                              }}>
                              <Grid container xs={1}>
                                {/* <p>hey</p> */}
                                <MailOutlineIcon
                                  color="primary"
                                  style={{
                                    marginTop: '6px',
                                    color: '#003671',
                                  }}
                                />
                              </Grid>
                              <Grid container xs={11}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                color="#000"
                                    align="left"
                                    // color="primary"
                                    fontWeight="fontWeightBold"
                                    paragraph
                                    style={{
                                      textAlign: 'left',
                                      marginBottom: '0px',
                                      marginTop: '6px',
                                      fontWeight: '600',
                                    }}>
                                    Connect Email
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    align="left"
                                color="#000"
                                    // color="primary"
                                    // fontWeight="fontWeightBold"
                                    paragraph
                                    style={{
                                      textAlign: 'left',
                                      // paddingBottom: '12px',
                                      marginBottom: '8px',
                                      marginTop: '8px',
                                      width: '100%',
                                    }}>
                                    Connect your mailbox to send email and get deliver / read
                                    receipts.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </div>
                {/* <div>
                  <Link
                    component={RouterLink}
                    to="/solutions"
                    variant="button"
                    color="textPrimary"
                    style={{
                      textTransform: 'capitalize',
                      fontWeight: '600',
                      fontSize: '1rem',
                      textDecoration: 'none',
                    }}
                    onMouseEnter={(event) => setActiveMenu('Solutions')}
                    onClick={() => {
                      setActiveMenu(undefined);
                      navigate('/solutions');
                    }}
                    className="bluelink">
                    Solutions
                  </Link>
                  {activeMenu === 'Solutions' ? (
                    <Grid
                      onMouseLeave={(event) => setActiveMenu(undefined)}
                      container
                      style={{
                        position: 'fixed',
                        top: '58px',
                        padding: '48px 24px 48px 48px',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        width: '100%',
                        height: '20%',
                        minHeight: '300px',
                        boxShadow:
                          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                      }}>
                      <Grid
                        xs={4}
                        item
                        onClick={() => {
                          setActiveMenu(undefined);
                          navigate('/solutions/sales');
                        }}
                        style={{
                          padding: '0px 16px',
                          cursor: 'pointer',
                          // borderRightStyle: 'solid',
                          // borderRightWidth: '1px',
                          // borderRightColor: 'rgb(235, 235, 235)',
                        }}>
                        <Grid container>
                          <Grid xs={2} lg={2} />
                          <Grid item xs={10} lg={10}>
                            <Typography
                              variant="h5"
                              align="center"
                              color="primary"
                              fontWeight="fontWeightBold"
                              paragraph
                              style={{
                                textAlign: 'left',
                                // paddingBottom: '12px',
                                fontWeight: '600',
                                // paddingLeft: '16px',
                              }}>
                              Sales
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} style={{cursor: 'pointer'}}>
                          <Grid container xs={2} justifyContent="right">
                            <AutoGraphIcon
                              style={{
                                marginTop: '6px',
                                color: '#003671',
                                marginRight: '12px',
                              }}
                            />
                          </Grid>
                          <Grid container xs={10}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                color="#000"
                                align="left"
                                // color="primary"
                                fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '6px',
                                  fontWeight: '600',
                                }}>
                                Salespeople
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                // color="primary"
                                color="#000"
                                // fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  // paddingBottom: '12px',
                                  marginBottom: '0px',
                                  marginTop: '16px',
                                  maxWidth: '90%',
                                }}>
                                Pass your sales targets and convert faster with these sales tools.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={4}
                        item
                        onClick={() => {
                          setActiveMenu(undefined);
                          navigate('/solutions/marketing');
                        }}
                        style={{
                          padding: '0px 16px',
                          cursor: 'pointer',
                          // borderRightStyle: 'solid',
                          // borderRightWidth: '1px',
                          // borderRightColor: 'rgb(235, 235, 235)',
                        }}>
                        <Grid container>
                          <Grid xs={2} />
                          <Grid item xs={10}>
                            <Typography
                              variant="h5"
                              align="center"
                              color="primary"
                              fontWeight="fontWeightBold"
                              paragraph
                              style={{
                                textAlign: 'left',
                                // paddingBottom: '12px',
                                fontWeight: '600',
                                // paddingLeft: '16px',
                              }}>
                              Marketing
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} style={{cursor: 'pointer'}}>
                          <Grid container xs={2} justifyContent="right">
                            <CampaignIcon
                              style={{
                                marginTop: '6px',
                                color: '#003671',
                                marginRight: '12px',
                              }}
                            />
                          </Grid>
                          <Grid container xs={10}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                color="#000"
                                // color="primary"
                                fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '6px',
                                  fontWeight: '600',
                                }}>
                                Marketing Professionals
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                color="#000"
                                // color="primary"
                                // fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  // paddingBottom: '12px',
                                  marginBottom: '0px',
                                  maxWidth: '90%',
                                  marginTop: '16px',
                                }}>
                                Understand your target audience and automate marketing research.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={4}
                        item
                        style={{
                          padding: '0px 16px',
                          // borderRightStyle: 'solid',
                          // borderRightWidth: '1px',
                          // borderRightColor: 'rgb(235, 235, 235)',
                        }}>
                        <Grid
                          container
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setActiveMenu(undefined);
                            navigate('/solutions/founders');
                          }}>
                          <Grid xs={2} />
                          <Grid item xs={10}>
                            <Typography
                              variant="h5"
                              align="center"
                              color="primary"
                              fontWeight="fontWeightBold"
                              paragraph
                              style={{
                                textAlign: 'left',
                                // paddingBottom: '12px',
                                fontWeight: '600',
                                // paddingLeft: '16px',
                              }}>
                              Founders
                            </Typography>{' '}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setActiveMenu(undefined);
                            navigate('/solutions/founders');
                          }}>
                          <Grid container xs={2} justifyContent="right">
                            <WorkspacePremiumIcon
                              style={{
                                marginTop: '6px',
                                color: '#003671',
                                marginRight: '12px',
                              }}
                            />
                          </Grid>
                          <Grid container xs={10}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                color="#000"
                                align="left"
                                // color="primary"
                                fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '6px',
                                  maxWidth: '90%',
                                  fontWeight: '600',
                                }}>
                                Founders and Business Owners
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                // color="primary"
                                color="#000"
                                // fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  // paddingBottom: '12px',
                                  marginBottom: '0px',
                                  marginTop: '16px',
                                }}>
                                Sell to other businesses, find potential investors and define your
                                market.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </div> */}

                {/* <Link
                variant="button"
                color="textPrimary"
                style={{
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  fontSize: '1rem',
                  textDecoration: 'none',
                }}
                onClick={() => {
                  navigate('features');
                }}
                className="link">
                Features
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                style={{
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  fontSize: '1rem',
                  textDecoration: 'none',
                }}
                onClick={() => {
                  navigate('technology');
                }}
                className="link">
                Technology
              </Link> */}
                {/* <Link
                variant="button"
                color="textPrimary"
                style={{
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  fontSize: '1rem',
                  textDecoration: 'none',
                }}
                cursor="pointer"
                onClick={() => {
                  navigate('inspiration');
                }}
                className="link">
                Inspiration
              </Link> */}
                {/* <Link
                variant="button"
                color="textPrimary"
                onMouseEnter={(event) => setActiveMenu(undefined)}
                style={{
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  fontSize: '1rem',
                  textDecoration: 'none',
                }}
                cursor="pointer"
                onClick={() => {
                  navigate('insights');
                }}
                className="link">
                Insights
              </Link> */}
                {/* <div>
                  <Link
                    component={RouterLink}
                    to="/our-story"
                    variant="button"
                    color="textPrimary"
                    style={{
                      textTransform: 'capitalize',
                      fontWeight: '600',
                      fontSize: '1rem',
                      textDecoration: 'none',
                    }}
                    onMouseEnter={(event) => setActiveMenu('Resources')}
                    onClick={() => {
                      setActiveMenu(undefined);
                      navigate('/our-story');
                    }}
                    className="bluelink">
                    Resources
                  </Link>
                  {activeMenu === 'Resources' ? (
                    <Grid
                      onMouseLeave={(event) => setActiveMenu(undefined)}
                      container
                      style={{
                        position: 'fixed',
                        top: '58px',
                        padding: '48px 24px 48px 48px',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        width: '100%',
                        height: '20%',
                        minHeight: '300px',
                        boxShadow:
                          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                      }}>
                      <Grid
                        xs={4}
                        item
                        onClick={() => {
                          setActiveMenu(undefined);
                          navigate('/our-story');
                        }}
                        style={{
                          padding: '0px 16px',
                          cursor: 'pointer',
                          // borderRightStyle: 'solid',
                          // borderRightWidth: '1px',
                          // borderRightColor: 'rgb(235, 235, 235)',
                        }}>
                        <Grid container>
                          <Grid xs={2} lg={2} />
                          <Grid item xs={10} lg={10}>
                            <Typography
                              variant="h5"
                              align="center"
                              color="primary"
                              fontWeight="fontWeightBold"
                              paragraph
                              style={{
                                textAlign: 'left',
                                // paddingBottom: '12px',
                                fontWeight: '600',
                                // paddingLeft: '16px',
                              }}>
                              Our Story
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} style={{cursor: 'pointer'}}>
                          <Grid container xs={2} justifyContent="right">
                            <AutoStoriesIcon
                              style={{
                                marginTop: '6px',
                                color: '#003671',
                                marginRight: '12px',
                              }}
                            />
                          </Grid>
                          <Grid container xs={10}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                color="#000"
                                align="left"
                                // color="primary"
                                fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '6px',
                                  fontWeight: '600',
                                }}>
                                How we started
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                color="#000"
                                // color="primary"
                                // fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  // paddingBottom: '12px',
                                  marginBottom: '0px',
                                  marginTop: '16px',
                                  maxWidth: '90%',
                                }}>
                                Find out a little more about the Find Me Sales journey.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={4}
                        item
                        onClick={() => {
                          setActiveMenu(undefined);
                          navigate('/insights');
                        }}
                        style={{
                          padding: '0px 16px',
                          cursor: 'pointer',
                          // borderRightStyle: 'solid',
                          // borderRightWidth: '1px',
                          // borderRightColor: 'rgb(235, 235, 235)',
                        }}>
                        <Grid container>
                          <Grid xs={2} />
                          <Grid item xs={10}>
                            <Typography
                              variant="h5"
                              align="center"
                              color="primary"
                              fontWeight="fontWeightBold"
                              paragraph
                              style={{
                                textAlign: 'left',
                                // paddingBottom: '12px',
                                fontWeight: '600',
                                // paddingLeft: '16px',
                              }}>
                              Insights
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} style={{cursor: 'pointer'}}>
                          <Grid container xs={2} justifyContent="right">
                            <InsightsIcon
                              style={{
                                marginTop: '6px',
                                color: '#003671',
                                marginRight: '12px',
                              }}
                            />
                          </Grid>
                          <Grid container xs={10}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                color="#000"
                                // color="primary"
                                fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '6px',
                                  fontWeight: '600',
                                }}>
                                Updates, insights and events
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                align="left"
                                color="#000"
                                // color="primary"
                                // fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  // paddingBottom: '12px',
                                  marginBottom: '0px',
                                  maxWidth: '90%',
                                  marginTop: '16px',
                                }}>
                                Read our monthly updates, get insights from Find Me Sales and see
                                events.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={4}
                        item
                        style={{
                          padding: '0px 16px',
                        }}>
                        <Grid
                          container
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setActiveMenu(undefined);
                            navigate('/support');
                          }}>
                          <Grid xs={2} />
                          <Grid item xs={10}>
                            <Typography
                              variant="h5"
                              align="center"
                              color="primary"
                              fontWeight="fontWeightBold"
                              paragraph
                              style={{
                                textAlign: 'left',
                                fontWeight: '600',
                              }}>
                              Support
                            </Typography>{' '}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setActiveMenu(undefined);
                            navigate('/support');
                          }}>
                          <Grid container xs={2} justifyContent="right">
                            <LiveHelpIcon
                              style={{
                                marginTop: '6px',
                                color: '#003671',
                                marginRight: '12px',
                              }}
                            />
                          </Grid>
                          <Grid container xs={10}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                color="#000"
                                align="left"
                                fontWeight="fontWeightBold"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '6px',
                                  maxWidth: '90%',
                                  fontWeight: '600',
                                }}>
                                Live chat and email support
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body"
                                color="#000"
                                align="left"
                                paragraph
                                style={{
                                  textAlign: 'left',
                                  marginBottom: '0px',
                                  marginTop: '16px',
                                }}>
                                Quickly get answers and support by email or live chat when you need
                                it.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </div> */}
                <Link
                  component={RouterLink}
                  to="/pricing?d=Monthly"
                  variant="button"
                  color="textPrimary"
                  onMouseEnter={(event) => setActiveMenu(undefined)}
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    fontSize: '1rem',
                    textDecoration: 'none',
                  }}
                  cursor="pointer"
                  onClick={() => {
                    navigate('pricing');
                  }}
                  className="bluelink">
                  Pricing
                </Link>
              </React.Fragment>
              {/* )} */}
              {/* </nav> */}
              {/* {!isBelowFold ? (
                <Typography
                  // variant="h5"
                  align="center"
                  paragraph
                  to="/login"
                  onClick={() => {
                    navigate({
                      pathname: '/login',
                    });
                  }}
                  style={{
                    marginRight: '124px',
                    fontSize: '16px',
                    marginBottom: '0px',
                    // minHeight: '78px',
                    textAlign: 'left',
                    color: '#003671',
                    fontWeight: '600',
                    cursor: 'pointer',
                    // textDecoration: 'none',
                  }}>
                  Sign In
                </Typography>
              ) : ( */}
              {/* {isBelowFold && ( */}
              <Button
                component={RouterLink}
                to="/free-trial"
                onClick={() => {
                  navigate({
                    pathname: '/free-trial',
                  });
                }}
                color="primary"
                onMouseEnter={(event) => setActiveMenu(undefined)}
                variant="contained"
                style={{
                  textTransform: 'capitalize',
                  fontWeight: '600',
                  minWidth: '101px',
                  borderRadius: '24px',
                  fontSize: '1rem',
                  marginRight: '0px',
                }}
                className="whitelink">
                Start Free
              </Button>
              <Typography
                // variant="h5"
                align="center"
                paragraph
                to="/login"
                onClick={() => {
                  navigate({
                    pathname: '/login',
                  });
                }}
                style={{
                  fontSize: '16px',
                  marginBottom: '0px',
                  // minHeight: '78px',
                  textAlign: 'left',
                  color: '#003671',
                  fontWeight: '600',
                  cursor: 'pointer',
                  padding: '0px 0px 0px 30px',
                  // textDecoration: 'none',
                }}>
                Sign In
              </Typography>
              {/* )} */}
            </Hidden>
            <Hidden mdUp>
              <div>
                <IconButton
                  color="inherit"
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  aria-label="Menu"
                  className="guestmenu"
                  onClick={handleClick}
                  size="large">
                  {anchorEl ? <CloseIcon /> : <MoreVertSharpIcon />}
                </IconButton>
                {/* <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  onClick={handleClose}> */}
                <Popover
                  id="mobile-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  onClick={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: '#fafafa !important',
                      boxShadow: 'none !important',
                      minWidth: '100% !important',
                      marginTop: '58px !important',
                      height: 'calc(100vh - 48px) !important', // Adjust 64px to the height of your header
                      // top: '64px !important', // Adjust 64px to the height of your header
                      left: 0,
                      right: 0,
                      bottom: 0,
                      margin: 0,
                      borderRadius: 0,
                      inset: '0px !important', // Override any default inset values
                    },
                  }} 
                  TransitionComponent={Grow}
                  transitionDuration={0} 
                  >
                  <MenuItem
                    sx={{
                      textAlign: 'center',
                      fontWeight: 600,
                      minHeight: '78px',
                      fontSize: '2rem !important',
                      lineHeight: '2.5rem !important',
                      '& .MuiListItemText-primary': {
                        fontSize: '2rem !important',
                      },
                    }}
                    onClick={() => {
                      navigate('leadsearch');
                    }}>
                    <ListItemText primary="Leadsearch" />
                  </MenuItem>
                  <MenuItem
                    sx={{
                      textAlign: 'center',
                      fontWeight: 600,
                      minHeight: '78px',
                      fontSize: '2rem !important',
                      lineHeight: '2.5rem !important',
                      '& .MuiListItemText-primary': {
                        fontSize: '2rem !important',
                      },
                    }}
                    onClick={() => {
                      navigate('product');
                    }}>
                    <ListItemText primary="Product" />
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minHeight: '78px',
                      textAlign: 'center',
                      fontWeight: 600,
                      fontSize: '2rem !important',
                      lineHeight: '2.5rem !important',
                      '& .MuiListItemText-primary': {
                        fontSize: '2rem !important',
                      },
                    }}
                    onClick={() => {
                      navigate('solutions');
                    }}>
                    <ListItemText primary="Solutions" />
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minHeight: '78px',
                      textAlign: 'center',
                      fontWeight: 600,
                      fontSize: '2rem !important',
                      lineHeight: '2.5rem !important',
                      '& .MuiListItemText-primary': {
                        fontSize: '2rem !important',
                      },
                    }}
                    onClick={() => {
                      navigate('insights');
                    }}>
                    <ListItemText primary="Insights" />
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minHeight: '78px',
                      textAlign: 'center',
                      fontWeight: 600,
                      fontSize: '2rem !important',
                      lineHeight: '2.5rem !important',
                      '& .MuiListItemText-primary': {
                        fontSize: '2rem !important',
                      },
                    }}
                    onClick={() => {
                      navigate('pricing');
                    }}>
                    <ListItemText primary="Pricing" />
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minHeight: '78px',
                      textAlign: 'center',
                      fontWeight: 600,
                      fontSize: '2rem !important',
                      lineHeight: '2.5rem !important',
                      '& .MuiListItemText-primary': {
                        fontSize: '2rem !important',
                      },
                    }}
                    onClick={() => {
                      navigate({
                        pathname: '/login',
                      });
                    }}>
                    <ListItemText primary="Sign in" />
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minHeight: '78px',
                      textAlign: 'center',
                      fontWeight: 600,
                      fontSize: '2rem !important',
                      lineHeight: '2.5rem !important',
                      '& .MuiListItemText-primary': {
                        fontSize: '2rem !important',
                      },
                    }}
                    onClick={() => {
                      navigate({
                        pathname: '/free-trial',
                      });
                    }}>
                    <ListItemText primary="Start Free" />
                  </MenuItem>
                </Popover>
                {/* </StyledMenu> */}
              </div>
            </Hidden>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
};
