import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import './lang/i18n';
import './App.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';
import chalk from 'chalk';
import App from './App';
import configureStore from './store';
import 'reactflow/dist/style.css';

let preloadedState = {};

if (typeof window !== 'undefined') {
  window.onunhandledrejection = function (event) {
    const stack = event.reason.stack || '';
    const match = stack.match(/at\s+(.*):(\d+):(\d+)/);
    const fileName = match ? match[1] : 'unknown file';
    const lineNumber = match ? match[2] : 'unknown line';
    const columnNumber = match ? match[3] : 'unknown column';
    console.log(
      chalk.red(
        `Unhandled Promise Rejection in ${fileName} at line ${lineNumber}, column ${columnNumber}:`
      ),
      event.reason
    );
  };
}



if (typeof window !== 'undefined') {
  preloadedState = window.__PRELOADED_STATE__;
  if (window.__COMPANY__) {
    preloadedState.company = window.__COMPANY__;
  }
}

// const {REACT_APP_GOOGLE_CLIENT_ID} = process.env;
// console.log('PROCESS ENV', process.env.REACT_APP_GOOGLE_CLIENT_ID)
// Configure the Redux store with preloaded state
const { store, persistor } = configureStore(preloadedState);

const rootElement = document.getElementById('root');

loadableReady(() => {
  hydrateRoot(
    rootElement,
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
              <App />
            {/* </PersistGate> */}
          </GoogleOAuthProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  );
});
