/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { REGISTER_ACTION, CLEAR_AUTH_ACTION } from 'app/actions/authAction';
import { HTTP_NEWSLETTER_JOIN } from 'app/services/httpClient/email.service';
import { HTTP_REQUEST_EMAIL_VERIFY } from 'app/services/httpClient/auth.service';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import HelmetMaker from 'app/utils/Helmet';
import ReactGA from 'react-ga4';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { toast } from 'react-toastify';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import withRouter from 'app/utils/withRouter';

const moment = require('moment');

function mapStateToProps(state) {
  return {
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_REGISTER: (payload) => dispatch(REGISTER_ACTION(payload)),
    DISPATCH_CLEAR_AUTH: () => dispatch(CLEAR_AUTH_ACTION()),
  };
}

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameerror: false,
      fullname: undefined,
      email: undefined,
      newsletter: false,
      Payload: {
        firstName: null,
        lastName: null,
        fullName: null,
        nameerror: false,
        businessName: null,
        jobTitle: null,
        agreeTerms: false,
        email: null,
        phone: null,
        password: null,
        confirm_password: null,
        Payload: {},
        sub_id: 'undefined',
        sub_created: 'undefined',
        current_period_end: 'undefined',
        current_period_start: 'undefined',
        stripeCustomerId: 'undefined',
        cancel_at: 'undefined',
        cancel_at_period_end: false,
        canceled_at: 'undefined',
        status: 'undefined',
        max_profiles: 1,
        paymentMethod_id: 'undefined',
        paymentMethod_brand: 'undefined',
        last4: 'undefined',
        exp_month: 'undefined',
        exp_year: 'undefined',
        nameOnCard: 'undefined',
        billingAddress: 'undefined',
        city: 'undefined',
        postCodeZip: 'undefined',
        country: 'undefined',
        subscription: 'free',
        mailbox: {
          user: null,
          password: {},
          outgoinghost: null,
          incominghost: null,
        },
      },
    };
    // bind event handlers in class components.
    this.HandleInputChange = this.HandleInputChange.bind(this);
    this.HandleCheckboxChange = this.HandleCheckboxChange.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.UpdateState = this.UpdateState.bind(this);
  }

  componentDidMount() {
    // const { GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE } =
    //   this.props;
    // const { isAuthenticated, token } = GET_AUTH_STATE;
    // if (isAuthenticated && token !== null) {
    //   this.props.navigate('/leadsearch');
    // }
    // if (GET_ERRORS_STATE.length !== 0) {
    //   toast.error(GET_ERRORS_STATE);
    //   DISPATCH_CLEAR_AUTH();
    // }
    // ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    //   if (value !== this.state.password) {
    //     return false;
    //   }
    //   return true;
    // });
  }

  componentDidUpdate(prevState) {
    // const { GET_ERRORS_STATE, GET_AUTH_STATE } = this.props;
    // if (
    //   GET_AUTH_STATE &&
    //   GET_AUTH_STATE.isAuthenticated !==
    //     prevState.GET_AUTH_STATE.isAuthenticated
    // ) {
    //   if (GET_AUTH_STATE.isAuthenticated) {
    //     this.props.navigate('/success/?m=free-trial');
    //   }
    // }
    // if (prevState !== this.state) {
    //   ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    //     if (value !== this.state.Payload.password) {
    //       return false;
    //     }
    //     return true;
    //   });
    // }
    if (
      !prevState.newsletter &&
      typeof window !== 'undefined' &&
      window.location.pathname === '/newsletter' &&
      (Cookies.get('newsletter') || this.state.newsletter)
    ) {
      toast.success("You've already signed up for our newsletter.");
      this.props.navigate('/insights');
    }
    // if (GET_ERRORS_STATE.length !== 0) {
    //   toast.error(GET_ERRORS_STATE[0].message);
    // }
  }

  UpdateState(state) {
    this.setState(state);
  }

  HandleInputChange(event) {
    this.setState({
      nameerror: false,
      [event.target.name]: event.target.value,
    });
  }

  HandleCheckboxChange(event) {
    toast.dismiss();
    if (this.state.Payload.agreeTerms === false) {
      this.setState({
        Payload: {
          ...this.state.Payload,
          agreeTerms: true,
        },
      });
    } else {
      this.setState({
        Payload: {
          ...this.state.Payload,
          agreeTerms: false,
        },
      });
    }
  }

  async HandleSubmit(event) {
    event.preventDefault();
    // console.log('NEWSLETTER SUBMIT');
    // if (this.state.Payload.agreeTerms === false) {
    //   toast.error('You must agree with our Terms and Conditions to register.');
    // } else {
    const firstName = '';
    const lastName = '';
    const name = this.state.fullname.trim();
    const all = name.split(' ').length;
    // console.log('NEWSLETTER SUBMIT');
    if (all < 2) {
      // setLoading(false);
      // setNameError(true);
      this.setState({
        nameerror: true,
      });
      // window.scrollTo(0, 60);
      toast.error('Please enter your full name');
    } else {
      const fullpayload = {
        email: this.state.email,
        firstName: name.split(' ')[0],
        lastName: name.split(' ').slice(1).join(' '),
      };
      // console.log('NEWSLETTER SUBMIT PAYLOAD', fullpayload);
      await HTTP_NEWSLETTER_JOIN(fullpayload)
        .then((response) => response.json())
        .then((response) => {
          const reslength = response.length;
          if (reslength !== 0) {
            // console.log('RESPONSE NEWSLETTER', response, response.status);
            // const leaddata = response.response.data;
            const { status, message } = response.response;
            if (status === 418) {
              toast.error(message);
            } else if (status === 400) {
              toast.error(message);
            } else if (status === 200) {
              Cookies.set('newsletter', true);
              this.setState({ newsletter: true });
              toast.success(
                'Subscription success!! You will now receive our newsletter.'
              );
            }
            // const {docs} = leaddata;
            // this.setState({
            //   leads: leaddata,
            //   loading: false,
            //   loadinglead: false,
            //   count: 0,
            //   countload: true,
            // });
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
        });
      // const {DISPATCH_REGISTER} = this.props;
      // await DISPATCH_REGISTER(this.state.Payload).then(
      //   this.props.navigate('/success/?m=free-trial')
      // );
      // await HTTP_REQUEST_EMAIL_VERIFY(this.state.Payload);
      // ReactGA.event({
      //   category: 'User',
      //   action: 'Trial',
      // });
    }
    // this.props.navigate('/leadsearch?m=confirm-email');
    // }
  }

  render() {
    const { t: lang, navigate } = this.props;
    const helmetload = {
      title: 'Newsletter | Find Me Sales',
      description:
        'Subscribe to our Newsletter to get the lastest in AI sales news, receive discounts codes and our monthly update.',
      url: 'https://findmesales.com/newsletter',
      // image:
      //   'https://storage.cloud.google.com/find-me-sales-bucket/assets/leadsearchhome.jpg',
      // keywords: 'Leads, Sales',
    };
    return Cookies.get('newsletter') || this.state.newsletter ? null : (
      <Fragment>
        {this.props.location.pathname === '/newsletter' ? (
          <HelmetMaker props={helmetload} />
        ) : null}
        {this.props.location.pathname === '/free-trial' ||
        this.props.location.pathname === '/login' ? null : (
          <Container
            // className="register-container"
            component="main"
            maxWidth="sm"
            style={{
              paddingBottom: '96px',
              minWidth: '96%',
              maxWidth: '96%',
              boxShadow:
                '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <div className="regpaper">
              <Grid
                container
                spacing={0}
                justifyContent="center"
                style={{ paddingBottom: '0px' }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  color="primary"
                  fontWeight="fontWeightBold"
                  paragraph
                  style={{
                    minWidth: '100px',
                    paddingTop: '3%',
                    // paddingBottom: '12px',
                    marginBottom: '0px',
                    fontWeight: '600',
                  }}
                >
                  Newsletter
                </Typography>
              </Grid>
              <Grid container>
                {/* <Hidden mdDown> */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ padding: '7% 5% 0px', maxWidth: '600px' }}
                  // direction="column"
                  // alignItems="center"
                  // justifyContent="center"
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    paragraph
                    align="justify"
                    style={{
                      textAlign: 'center',
                      padding: '0px 14px 27px',
                      fontWeight: '600',
                      fontSize: '19px',
                    }}
                  >
                    Subscribe today
                  </Typography>
                  <Typography
                    variant="body2"
                    paragraph
                    align="justify"
                    style={{ textAlign: 'center', padding: '0px 14px 0px' }}
                  >
                    • See exclusive deals and discounts
                  </Typography>
                  <Typography
                    variant="body2"
                    paragraph
                    align="justify"
                    style={{ textAlign: 'center', padding: '24px 14px 0px' }}
                  >
                    • The latest AI sales innovation news
                  </Typography>
                  <Typography
                    variant="body2"
                    paragraph
                    align="justify"
                    style={{ textAlign: 'center', padding: '24px 14px 0px' }}
                  >
                    • Get our monthly company updates
                  </Typography>
                </Grid>
                {/* </Hidden> */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  // direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ padding: '7% 0px 0px 0px ' }}
                >
                  <Grid item>
                    <ValidatorForm
                      id="newsletter"
                      onError={(errors) => {
                        toast.error(errors);
                      }}
                      onSubmit={this.HandleSubmit}
                    >
                      <CssBaseline />
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextValidator
                            autoComplete="fullName"
                            name="fullName"
                            value={this.state.fullname}
                            variant="outlined"
                            onChange={(e) =>
                              this.setState({ fullname: e.target.value })
                            }
                            error={this.state.nameerror}
                            fullWidth
                            // autoFocus
                            id="firstName"
                            label="Full Name"
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextValidator
                            variant="outlined"
                            fullWidth
                            onChange={this.HandleInputChange}
                            id="email"
                            label="Email Address"
                            name="email"
                            value={this.state.email}
                            autoComplete="email"
                            validators={['required', 'isEmail']}
                            errorMessages={[
                              'This field is required',
                              'Email is not valid',
                            ]}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        form="newsletter"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="registersubmit"
                      >
                        Subscribe
                      </Button>
                      <Grid
                        container
                        justifyContent="center"
                        style={{ textAlign: 'center' }}
                      >
                        {/* <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          paragraph
                          align="justify"
                          style={{textAlign: 'center'}}>
                          No card required
                        </Typography>
                      </Grid> */}
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            paragraph
                            align="justify"
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              padding: '0px',
                            }}
                          >
                            By clicking 'Subscribe' you agree to our{' '}
                            <Link
                              target="_blank"
                              href="/terms"
                              variant="body1"
                              align="left"
                              style={{ fontSize: '12px' }}
                              className="pagelink"
                            >
                              Terms
                            </Link>{' '}
                            and{' '}
                            <Link
                              target="_blank"
                              href="/privacy"
                              variant="body1"
                              style={{ fontSize: '12px' }}
                              align="left"
                              className="pagelink"
                            >
                              Privacy Policy
                            </Link>
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Link
                          style={{cursor: 'pointer', fontSize: '12px'}}
                          onClick={() => {
                            this.props.navigate('login');
                          }}
                          variant="body2">
                          Already have an account? Sign in
                        </Link>
                      </Grid> */}
                      </Grid>
                    </ValidatorForm>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>
        )}
      </Fragment>
    );
  }
}

Newsletter.propTypes = {
  DISPATCH_REGISTER: PropTypes.func.isRequired,
  DISPATCH_CLEAR_AUTH: PropTypes.func.isRequired,
  GET_ERRORS_STATE: PropTypes.array.isRequired,
  GET_AUTH_STATE: PropTypes.object.isRequired,
  // t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Newsletter));
